import React from 'react'
import styled from 'styled-components'

function Login() {
  return (
    <Container>
        <ContentBox>
            <CTA>
                <CTALogoOne src="/images/cta-logo-one.svg" />
                <SignUp>ASSINE JÁ</SignUp>
                <Description>
                    Get Premier Access to Raya and the Last Dragon for 
                    an additional fee with a Disney+ subscription. 
                    As of 03/26/21, the price of Disney+ and The Disney Bundle
                    will increase by $1.
                </Description>
                <CTALogoTwo src="/images/cta-logo-two.png" />
            </CTA>
        </ContentBox>
    </Container>
  )
}

export default Login

const Container = styled.div`
    position: relative;
    height: calc(100vh - 70px);
    display: flex;
    align-items: center;
    justify-content: center;

    &:before {
        position: absolute;
        background-position: top;
        background-size: cover;
        background-repeat: no-repeat;
        background-image: url("/images/login-background.jpg");
        content: '';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0; 
        opacity: 0.7;
        z-index: -1;
    }
`

const ContentBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

const CTA = styled.div`
    max-width: 650px;
    padding: 80px 40px;
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
`

const CTALogoOne = styled.img``

const SignUp = styled.a`
    cursor: pointer;
    margin-top: 15px;
    width: 100%;
    background-color: #0063e5;
    font-weight: bold;
    padding: 17px 0;
    color: #f9f9f9;
    border-radius: 4px;
    text-align: center;
    font-size: 18px;
    transition: all 250ms;
    letter-spacing: 1.5px;

    &:hover {
        background: #0483ee;
    }
`

const Description = styled.p`
    font-size: 11px;
    letter-spacing: 1.5;
    text-align: center;
    line-height: 1.5;
`

const CTALogoTwo = styled.img`
    width: 90%;
`